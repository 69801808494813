<script>
import { authComputed } from '@state/helpers'

import MetisMenu from 'metismenujs/dist/metismenujs'
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlCi: process.env.VUE_APP_CI_URL,
    }
  },
  computed: {
    ...authComputed,
    ...mapState('user', ['listFlexmod'])
  },
  mounted: function () {
    // this.getListFlexmod()

    console.log(this.listFlexmod, 'THIS LIST FLEXMOD')
    // dashboard doang
    var inbox = document.querySelectorAll('#dashboard')
    for (var j = 0; j < inbox.length; j++) {
      if (window.location.pathname === inbox[j].pathname) {
        inbox[0].classList.add('active')
      }
    }

    if (!this.isCondensed) {
      // eslint-disable-next-line no-unused-vars
      var menuRef = new MetisMenu('#side-menu', {
        toggle: false,
      })

      var links = document.getElementsByClassName('side-nav-link-ref')
      var matchingMenuItem = null
      for (var i = 0; i < links.length; i++) {
        if (window.location.pathname === links[i].pathname) {
          matchingMenuItem = links[i]
          break
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active')
        var parent = matchingMenuItem.parentElement

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add('active')
          const parent2 = parent.parentElement
          if (parent2) {
            parent2.classList.add('in')
          }
          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.add('active')
            var childAnchor = parent3.querySelector('.has-dropdown')
            if (childAnchor) childAnchor.classList.add('active')
          }

          const parent4 = parent3.parentElement
          if (parent4) parent4.classList.add('in')
          const parent5 = parent4.parentElement
          if (parent5) parent5.classList.add('active')
        }
      }
    }

    var querySideMenu = document.querySelectorAll('.metismenu > li > ul')

    for (
      let mainNode = 0, test = querySideMenu.length;
      mainNode <= test;
      mainNode++
    ) {
      for (
        let insideNode = 0, teste = querySideMenu[insideNode].childNodes.length;
        insideNode <= teste;
        insideNode++
      ) {
        for (
          let c = 0,
            counterDisplay = 0,
            insideNodeLength = querySideMenu[insideNode].childNodes.length;
          c < insideNodeLength;

        ) {
          let v = querySideMenu[insideNode].childNodes[c]
          if (v.style.display === 'none') {
            counterDisplay++
          }
          c++
          if (c === insideNodeLength) {
            if (c === counterDisplay) {
              querySideMenu[insideNode].parentElement.style.display = 'none'
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('user', ['getListFlexmod']),
    canUser: function (key) {
      console.log(key, 'key permission')
      console.log(localStorage.getItem('userPermissions'))
      if (
        !JSON.parse(localStorage.getItem('userPermissions')).includes(
          key.replace(/'/g, '').replace(/"/g, '')
        )
      ) {
        // vnode.elm.style.display = "none";
      }
    },

    /**
     * small sidebar
     */
    smallSidebar() {
      document.body.classList.add('left-side-menu-sm')
      document.body.classList.remove('left-side-menu-dark')
      document.body.classList.remove('topbar-light')
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('enlarged')
    },

    /**
     * Dark sidebar
     */
    darkSidebar() {
      document.body.classList.remove('left-side-menu-sm')
      document.body.classList.add('left-side-menu-dark')
      document.body.classList.remove('topbar-light')
      document.body.classList.remove('boxed-layout')
    },

    /**
     * Light Topbar
     */
    lightTopbar() {
      document.body.classList.add('topbar-light')
      document.body.classList.remove('left-side-menu-dark')
      document.body.classList.remove('left-side-menu-sm')
      document.body.classList.remove('boxed-layout')
    },

    /**
     * Sidebar collapsed
     */
    sidebarCollapsed() {
      document.body.classList.remove('left-side-menu-dark')
      document.body.classList.remove('left-side-menu-sm')
      document.body.classList.toggle('enlarged')
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('topbar-light')
    },

    /**
     * Boxed Layout
     */
    boxedLayout() {
      document.body.classList.add('boxed-layout')
      document.body.classList.remove('left-side-menu-dark')
      document.body.classList.add('enlarged')
      document.body.classList.remove('left-side-menu-sm')
    },
    formatPermission() {

      return 'FLEXMOD_EX_4_JOB_ORDER_ENABLE'
    }
  },
}
</script>

<template>
  <!-- - Sidemenu -->

  <div v-cloak id="sidebar-menu">
    <ul id="side-menu" class="metismenu">
      <!-- <li class="menu-title">
				<span>Marketing</span>
			</li> -->

      <li v-module="'Marketing'">
        <a
          href="javascript:void(0);"
          aria-expanded="false"
          class="has-dropdown"
        >
          <i class="mr-2 mdi mdi-book-open" style="font-size: 20px"></i>
          <span>Marketing</span>
          <span class="menu-arrow"></span>
        </a>
        <ul class="nav-second-level" aria-expanded="false">
          <li v-can="'MARKETING_DASHBOARD_ENABLE'">
            <router-link id="dashboard" to="/" class="side-nav-link-ref">
            <span>
              <img src="@assets/images/icon-dashboard.svg" alt="folder" />
            </span>
              <span>Dashboard</span>
            </router-link>
          </li>

          <li v-can="'CONTACT_MANAGEMENT_ENABLE'">
            <a
              href="javascript:void(0);"
              aria-expanded="false"
              class="has-dropdown"
            >
              <span style="margin-right:2px">
                <img src="@assets/images/user-tie.svg" alt="folder" />
              </span>
              <span>Leads Customer</span>
              <span class="menu-arrow"></span>
            </a>
            <ul class="nav-thrid-level" aria-expanded="false">
              <li>
                <router-link
                  tag="a"
                  to="/leads-customer/board"
                  class="side-nav-link-ref"
                >
                  <span>
                    <img :src="urlCi + '/assets/admin/images/icon-board.svg'" />
                  </span>
                  <span>Board</span>
                </router-link>
              </li>
              <li>
                <router-link
                  tag="a"
                  to="/leads-customer/"
                  class="side-nav-link-ref"
                >
                  <span>
                    <img :src="urlCi + '/assets/admin/images/icon-list.svg'" />
                  </span>
                  <span>List</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li v-can="'QUOTATION_ENABLE'">
            <router-link id="quotation" tag="a" to="/quotation" class="side-nav-link-ref">
            <span style="margin-right:2px">
              <img src="@assets/images/icon-quotation.svg" alt="folder" />
            </span>
              <span>Quotation</span>
            </router-link>
          </li>

          <!--          <li v-can="'REFUND_ENABLE'">-->
          <!--            <a-->
          <!--              id="refund"-->
          <!--              :href="urlCi + '/admin/jobfile'"-->
          <!--              class="side-nav-link"-->
          <!--            >-->
          <!--              <img src="@assets/images/icon-refund.svg" alt="folder" />-->
          <!--              <span>Refund</span>-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </li>

      <li v-module="'Operation'">
        <a
          href="javascript:void(0);"
          aria-expanded="false"
          class="has-dropdown"
        >
          <i
            class="mdi mdi-checkbox-multiple-marked-circle-outline"
            style="font-size: 20px"
          ></i>
          <span>Operational</span>
          <span class="menu-arrow"></span>
        </a>
        <ul aria-expanded="false" class="nav-second-level">
          <li v-can="'OPERATION_DASHBOARD_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/dashboard_ops'"
              aria-expanded="false"
            >
              <span style="margin-right:3px">
                <img src="@assets/images/icon-dashboard.svg" />
              </span>
              <span class="">Dashboard</span>
            </a>
          </li>

          <li v-can="'JOBFILE_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/jobfile'"
              aria-expanded="false"
            >
              <span style="margin-right:1px">
                <img src="@assets/images/icon-jobfile.svg" />
              </span>
              <span class="">Jobfile</span>
            </a>
          </li>

          <li v-can="'TRANSACTION_COPY_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/transaction_copy'"
              aria-expanded="false"
            >
              <span>
                <img src="@assets/images/icon-transcopy.svg" />
              </span>
              <span class="">Transaction Copy</span>
            </a>
          </li>

          <li v-can="'OTHER_PAYMENT_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/other_payment'"
              aria-expanded="false"
            >
              <span style="margin-right:px">
                <img src="@assets/images/icon-otherpayment.svg" />
              </span>
              <span class="">Other Payment</span>
            </a>
          </li>

          <li v-can="'JOURNAL_VOUCHER_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/journal_voucher'"
              aria-expanded="false"
            >
              <span style="margin-right:2px">
                <img src="@assets/images/icon-otherpayment.svg" />
              </span>
              <span class="">Journal Voucher</span>
            </a>
          </li>

          <li v-can="'ACTIVITY_LOG_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/activity_log'"
              aria-expanded="false"
            >
              <span style="margin-right:3px">
                <img src="@assets/images/icon-activity.svg" />
              </span>
              <span class="">Activity Log</span>
            </a>
          </li>

          <li v-can="'DRAFT_TRANSACTION_ALL_VIEW'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/list-transaksi'"
              aria-expanded="false"
            >
              <span style="margin-right:4px">
                <img src="@assets/images/icon-activity.svg" />
              </span>
              <span class="">Draft Transaction</span>
            </a>
          </li>
          <li v-can="'LIST_SPAREPART_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/list_sparepart'"
              aria-expanded="false"
            >
              <span style="margin-right:5px">
                <img src="@assets/images/icon-list-sparepart.svg" />
              </span>
              <span class="">List Sparepart</span>
            </a>
          </li>
          <li v-can="'CASH_ADVANCE_ENABLE'">
            <router-link id="MultiBuyingDownPayment" tag="a" to="/MultiBuyingDownPayment" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Cash Advance</span>
            </router-link>
          </li>
          <li v-can="'ACCRUAL_COST_STEP_2_ENABLE'">
            <router-link id="ReceivableItem" tag="a" to="/ReceivableItem" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Create Accrual Cost Step 2</span>
            </router-link>
          </li>
					<li v-can="'ACCRUAL_COST_STEP_1_ENABLE'">
            <router-link id="PurchaseOrder" tag="a" to="/purchase-order" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Create Accrual Cost Step 1</span>
            </router-link>
          </li>
          <li v-can="'VENDOR_INVOICE_ENABLE'">
            <router-link id="MultiPurchaseInvoice" tag="a" to="/MultiPurchaseInvoice" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Vendor Invoice / Settlement</span>
            </router-link>
          </li>
					<li v-can="'VENDOR_INVOICE_V2_ENABLE'">
            <router-link id="MultiPurchaseInvoiceV2" tag="a" to="/MultiPurchaseInvoiceV2" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Vendor Invoice / Settlement</span>
            </router-link>
          </li>
          <li  v-can="'TRACKING_SHIPMENT_ENABLE'">
            <router-link id="tracking_shipment" tag="a" to="/tracking-shipment" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Tracking Shipment</span>
            </router-link>
          </li>
          <li v-can="'SALES_INVOICE_MULTI_ENABLE'">
            <router-link id="MultiSalesInvoice" tag="a" to="/MultiSalesInvoice" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Sales Invoice Multi</span>
            </router-link>
          </li>
          <li v-can="'WORKING_INSTRUCTION_VENDOR_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/working_instruction_vendor'"
              aria-expanded="false"
            >
              <span style="margin-right:1px">
                <img :src="urlCi + '/assets/admin/images/assignment_send.svg'" />
              </span>
              <span class="">Working Instruction</span>
            </a>
          </li>
        </ul>
      </li>

      <li v-module="">
        <a
          href="javascript:void(0);"
          aria-expanded="false"
          class="has-dropdown"
        >
          <i
            class="mdi mdi-checkbox-multiple-marked-circle-outline"
            style="font-size: 20px"
          ></i>
          <span>TMS</span>
          <span class="menu-arrow"></span>
        </a>
        <ul aria-expanded="false" class="nav-second-level">
          <li v-can="'TMS_JOB_ORDER_ENABLE'">
            <router-link id="job_order" tag="a" to="/job-order?id_order_flexmod=0" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Job Order</span>
            </router-link>
          </li>
        </ul>
      </li>
      <!-- v-module="row.name" => Sementara pake v-can dulu, karena biar waktu group accessnyaa di ilangin aksesnyaa, modulenyaa jugaa ilang -->
      <li v-for="row in listFlexmod" :key="row.id" v-can="row.name.toUpperCase().split(' ').join('_') + '_JOB_ORDER_ENABLE'">
        <a
          href="javascript:void(0);"
          aria-expanded="false"
          class="has-dropdown"
        >
          <span>
              <img :src="urlCi + '/assets/admin/images/' + row.icon" alt="folder" width="20" />
            </span>
          <span>{{row.name}}</span>
          <span class="menu-arrow"></span>
        </a>
        <ul aria-expanded="false" class="nav-second-level">
          <li v-can="row.name.toUpperCase().split(' ').join('_') + '_JOB_ORDER_ENABLE'">
            <router-link id="job_order" tag="a" :to="'/job-order?id_flexmod=' + row.id + ''">
              <span style="margin-right:3px">
                <img src="@assets/images/icon-jobfile.svg" alt="folder" />
              </span>
              <span>Job Order</span>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- master  -->
      <li v-module="'Master'">
        <a
          href="javascript:void(0);"
          aria-expanded="false"
          class="has-dropdown"
        >
          <img src="@assets/images/icon-master.svg" alt="folder" />
          <span>Master</span>
          <span class="menu-arrow"></span>
        </a>
        <ul class="nav-second-level" aria-expanded="false">
          <li v-can="'AGENT_ENABLE'">
            <a :href="urlCi + '/admin/master_agent'" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img :src="urlCi + '/assets/admin/images/icon-agent.svg'" />
              </span>
              <span>Agent</span>
            </a>
          </li>
          <!-- <li>
						<a :href="urlCi + '/admin/master_airline'" class="side-nav-link-ref">
							<span>Airlines</span>
						</a>
					</li> -->
          <li v-can="'AUTONUMBER_ENABLE'">
            <a
              :href="urlCi + '/admin/master_autonumber'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:3px">
                <img
                  :src="urlCi + '/assets/admin/images/icon-autonumber.svg'"
                />
              </span>
              <span>Auto Number</span>
            </a>
          </li>
          <li v-can="'BRANCH_ENABLE'">
            <a :href="urlCi + '/admin/master_branch'" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img :src="urlCi + '/assets/admin/images/icon-branch.svg'" />
              </span>
              <span>Branch</span>
            </a>
          </li>
          <li v-can="'CURRENCY_ENABLE'">
            <a
              :href="urlCi + '/admin/master_currency'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:8px">
                <img :src="urlCi + '/assets/admin/images/icon-currency.svg'" />
              </span>
              <span>Currency</span>
            </a>
          </li>
          <li v-can="'CUSTOMER_ENABLE'">
            <a
              :href="urlCi + '/admin/master_customer'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-customer.svg'" />
              </span>
              <span>Customer</span>
            </a>
          </li>
          <li v-can="'DEPO_ENABLE'">
            <a :href="urlCi + '/admin/master_depo'" class="side-nav-link-ref">
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-depo.svg'" />
              </span>
              <span>Depo</span>
            </a>
          </li>
          <li v-can="'DRIVER_ENABLE'">
            <a :href="urlCi + '/admin/master_driver'" class="side-nav-link-ref">
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-driver.svg'" />
              </span>
              <span>Driver</span>
            </a>
          </li>
          <li v-can="'ITEM_ENABLE'">
            <a :href="urlCi + '/admin/master_item'" class="side-nav-link-ref">
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-item.svg'" />
              </span>
              <span>Item</span>
            </a>
          </li>
          <li v-can="'PORT_ENABLE'">
            <a :href="urlCi + '/admin/master_port'" class="side-nav-link-ref">
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-port.svg'" />
              </span>
              <span>Port</span>
            </a>
          </li>
          <li v-can="'REASON_ENABLE'">
            <router-link id="reason" tag="a" to="/reason/">
            <span style="margin-right:4px">
              <img
                :src="urlCi + '/assets/admin/images/icon-reason.svg'"
                alt="folder"
              />
              </span>
              <span>Reason</span>
            </router-link>
          </li>
          <li v-can="'SHIPPING_LINE_ENABLE'">
            <a
              :href="urlCi + '/admin/master_shipping'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-shipping.svg'" />
              </span>
              <span>Shipping Line</span>
            </a>
          </li>
          <li v-can="'TERM_ENABLE'">
            <a :href="urlCi + '/admin/master_term'" class="side-nav-link-ref">
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-term.svg'" />
              </span>
              <span>Term</span>
            </a>
          </li>
          <li v-can="'TRUCK_ENABLE'">
            <a :href="urlCi + '/admin/master_truck'" class="side-nav-link-ref">
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-truck.svg'" />
              </span>
              <span>Truck</span>
            </a>
          </li>
          <li v-can="'VENDOR_ENABLE'">
            <a :href="urlCi + '/admin/master_vendor'" class="side-nav-link-ref">
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-vendor.svg'" />
              </span>
              <span>Vendor</span>
            </a>
          </li>
          <li v-can="'ADDRESS_ENABLE'">
            <a
              :href="urlCi + '/admin/master_address'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-warehouse.svg'" />
              </span>
              <span>Address</span>
            </a>
          </li>
          <li v-can="'ADDRESS_V2_ENABLE'">
            <a
              :href="urlCi + '/admin/address'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-warehouse.svg'" />
              </span>
              <span>Address</span>
            </a>
          </li>
          <li v-can="'AUTHORIZED_ENABLE'">
            <a
              :href="urlCi + '/admin/master_authorized'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:6px">
                <img
                  :src="urlCi + '/assets/admin/images/file-signatures.svg'"
                />
              </span>
              <span>Authorized</span>
            </a>
          </li>
          <li v-can="'ASSIGNMENT_RECIPIENT_ENABLE'">
            <a
              :href="urlCi + '/admin/master_assignment_recipient'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:2px">
                <img
                  :src="urlCi + '/assets/admin/images/briefcase-variant.svg'"
                />
              </span>
              <span>Assignment</span>
            </a>
          </li>
          <li v-can="'PPJK_ENABLE'">
            <a :href="urlCi + '/admin/master_ppjk'" class="side-nav-link-ref">
              <span style="margin-right:4px">
                <img
                  :src="urlCi + '/assets/admin/images/office-building.svg'"
                />
              </span>
              <span>PPJK</span>
            </a>
          </li>
          <li v-can="'TAX_PERCENTAGE_ENABLE'">
            <a
              :href="urlCi + '/admin/master_percentage'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:3px">
                <img :src="urlCi + '/assets/admin/images/ic-tax.svg'" />
              </span>
              <span>Tax Percentage</span>
            </a>
          </li>
          <li v-can="'MASTER_WAREHOUSE_ENABLE'">
            <a
              :href="urlCi + '/admin/master_warehouse'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-warehouse.svg'" />
              </span>
              <span>Warehouse</span>
            </a>
          </li>
          <li v-can="'MASTER_CONTAINER_ENABLE'">
            <a
              :href="urlCi + '/admin/master_container'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-warehouse.svg'" />
              </span>
              <span>Master Container</span>
            </a>
          </li>
          <li v-can="'SHIPPER_&_CONSIGNEE_ENABLE'">
            <a
              :href="urlCi + '/admin/master_shipper'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-shipping.svg'" />
              </span>
              <span>Shipper & Consignee</span>
            </a>
          </li>
          <li v-can="'FOB_ENABLE'">
            <a
              :href="urlCi + '/admin/master_fob'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-shipping.svg'" />
              </span>
              <span>FOB</span>
            </a>
          </li>
          <li v-can="'STATUS_TRUCKING_SHIPMENT_ENABLE'">
            <a
              :href="urlCi + '/admin/master_status_tracking_shipment'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-warehouse.svg'" />
              </span>
              <span>Master Status Shipment</span>
            </a>
          </li>
        </ul>
      </li>

      <!-- report -->
      <li v-module="'Report'">
        <a
          href="javascript:void(0);"
          aria-expanded="false"
          class="has-dropdown"
        >
          <img src="@assets/images/icon-report.svg" alt="folder" />
          <span>Report</span>
          <span class="menu-arrow"></span>
        </a>
        <ul class="nav-second-level" aria-expanded="false">
          <li v-can="'REPORT_PROJECT_ENABLE'">
            <a
              :href="urlCi + '/admin/report_jobfile'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:5px">
                <img :src="urlCi + '/assets/admin/images/icon-project.svg'" />
              </span>
              <span>Project</span>
            </a>
          </li>
          <li v-can="'REPORT_LIFTING_ENABLE'">
            <a
              :href="urlCi + '/admin/report_lifting'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:5px">
                <img :src="urlCi + '/assets/admin/images/icon-lifting.svg'" />
              </span>
              <span>Lifting</span>
            </a>
          </li>
          <li v-can="'REPORT_LOGISTIC_ENABLE'">
            <a
              :href="urlCi + '/admin/report_logistic'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:5px">
                <img :src="urlCi + '/assets/admin/images/icon-logistic.svg'" />
              </span>
              <span>Logistic</span>
            </a>
          </li>
          <li v-can="'REPORT_DAILY_ENABLE'">
            <a :href="urlCi + '/admin/report_harian'" class="side-nav-link-ref">
              <span style="margin-right:2px">
                <img :src="urlCi + '/assets/admin/images/icon-daily.svg'" />
              </span>
              <span>Daily</span>
            </a>
          </li>
          <li v-can="'REPORT_TOP_EXPENS_ENABLE'">
            <a
              :href="urlCi + '/admin/report_top_expense'"
              class="side-nav-link-ref"
            >
              <span style="margin-right:4px">
                <img
                  :src="urlCi + '/assets/admin/images/icon-topexpense.svg'"
                />
              </span>
              <span>Top Expense</span>
            </a>
          </li>
          <li v-can="'STATEMENT_OF_ACCOUNT_ENABLE'">
            <a :href="urlCi + '/admin/report_soa'" class="side-nav-link-ref">
              <span style="margin-right:3px">
                <img :src="urlCi + '/assets/admin/images/icon-daily.svg'" />
              </span>
              <span>Statement Of Account</span>
            </a>
          </li>
          <li v-can="'BEST_CUSTOMER_ENABLE'">
            <router-link tag="a" to="/best-customer" class="side-nav-link-ref">
              <span style="margin-right:5px">
                <img :src="urlCi + '/assets/admin/images/icon-board.svg'" />
              </span>
              <span>Best Customer</span>
            </router-link>
          </li>
          <li v-can="'REPORT_ONTIME_ENABLE'">
            <router-link
              id="report_ontime_delay"
              tag="a"
              to="/report-ontime-delay"
              class="side-nav-link-ref"
            >
            <span style="margin-right:7px">
              <img src="@assets/images/icon-quotation.svg" alt="folder" />
            </span>
              <span>Report Top Ontime/Delay</span>
            </router-link>
          </li>
          <li v-can="'REPORT_LIST_TRANSACTION_ENABLE'">
            <router-link
              id="report_list_transaction"
              tag="a"
              to="/report-list-transaction"
              class="side-nav-link-ref"
            >
            <span style="margin-right:7px">
              <img src="@assets/images/icon-quotation.svg" alt="folder" />
            </span>
              <span>Report List Transaction</span>
            </router-link>
          </li>
          <li v-can="'REPORT_LIST_SPAREPART_ENABLE'">
            <router-link
              tag="a"
              to="/report-listsparepart"
              class="side-nav-link-ref"
            >
              <span style="margin-right:6px">
                <img :src="urlCi + '/assets/admin/images/icon-board.svg'" />
              </span>
              <span>Report List Sparepart</span>
            </router-link>
          </li>
          <li v-can="'REPORT_ESPR_ENABLE'">
            <router-link
              id="report_worksheet"
              tag="a"
              to="/report-espr"
              class="side-nav-link-ref"
            >
            <span style="margin-right:7px">
              <img src="@assets/images/icon-quotation.svg" alt="folder" />
            </span>
              <span>Report ESPR</span>
            </router-link>
          </li>
          <li v-can="'REPORT_EBILLING_ENABLE'">
            <router-link
              id="report_ebilling"
              tag="a"
              to="/report-ebilling"
              class="side-nav-link-ref"
            >
            <span style="margin-right:7px">
              <img src="@assets/images/icon-quotation.svg" alt="folder" />
            </span>
              <span>Report Ebilling</span>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- user management -->
      <li v-module="'Setting'">
        <a
          href="javascript:void(0);"
          aria-expanded="false"
          class="has-dropdown"
        >
          <i class="mdi mdi-settings" style="font-size: 20px"></i>
          <span>Setting</span>
          <span class="menu-arrow"></span>
        </a>
        <ul aria-expanded="false" class="nav-second-level">
          <li v-can="'ACCURATE_ACCESS_ENABLE'"
            ><a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/aol_access'"
              aria-expanded="false"
            >
              <span style="margin-right:3px">
                <img
                  :src="urlCi + '/assets/admin/images/icon-groupaccess.svg'"
                />
              </span>
              <span class="">Accurate Access</span></a
            ></li
          >

          <li v-can="'GROUP_ACCESS_ENABLE'"
            ><a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/group_access'"
              aria-expanded="false"
            >
              <span style="margin-right:3px">
                <img
                  :src="urlCi + '/assets/admin/images/icon-groupaccess.svg'"
                />
              </span>
              <span class="">Group Access</span></a
            ></li
          >

          <li v-can="'USER_ENABLE'"
            ><a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/user'"
              aria-expanded="false"
            >
              <span style="margin-right:4px">
                <img :src="urlCi + '/assets/admin/images/icon-user.svg'" />
              </span>
              <span class="">User</span></a
            ></li
          >

          <!-- End check user permission -->

          <li v-can="'NOTIFICATION_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/setting_email'"
              aria-expanded="false"
            >
              <span style="margin-right:5px">
                <img
                  :src="urlCi + '/assets/admin/images/icon-notification.svg'"
                />
              </span>
              <span class="">Notification</span>
            </a>
          </li>

          <li v-can="'DEFAULT_PARTY_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/setting'"
              aria-expanded="false"
            >
              <span style="margin-right:4px">
                <img
                  :src="urlCi + '/assets/admin/images/icon-emailsetting.svg'"
                />
              </span>
              <span class="">Default Party</span>
            </a>
          </li>

          <li v-can="'COMPANY_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/billing'"
              aria-expanded="false"
            >
              <span style="margin-right:7px">
                <img :src="urlCi + '/assets/admin/images/building-solid.svg'" />
              </span>
              <span class="">Billing</span></a
            >
          </li>
          <li v-can="'SETTING_GENERAL_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/general'"
              aria-expanded="false"
            >
              <span style="margin-right:4px">
                <img
                  :src="urlCi + '/assets/admin/images/icon-emailsetting.svg'"
                />
              </span>
              <span class="">General Setting</span></a
            >
          </li>
          <li v-can="'SETTING_JOB_ORDER_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/setting-job-order'"
              aria-expanded="false"
            >
              <span style="margin-right:4px">
                <img
                  :src="urlCi + '/assets/admin/images/icon-emailsetting.svg'"
                />
              </span>
              <span class="">General Job Order</span></a
            >
          </li>
          <li v-can="'SHIPMENT_ACCESS_ENABLE'">
            <router-link
              id="shipment_access"
              tag="a"
              to="/shipment-access"
              class="side-nav-link-ref"
            >
            <span style="margin-right:7px">
              <img :src="urlCi + '/assets/admin/images/icon-shipping.svg'" />
            </span>
              <span>Shipment Access</span>
            </router-link>
          </li>
          <li v-can="'MULTI_MEMBER_SESSION_ENABLE'">
            <a
              class="waves-effect waves-dark"
              :href="urlCi + '/admin/multi_member_integration'"
              aria-expanded="false"
            >
            <span style="margin-right:7px">
              <img :src="urlCi + '/assets/admin/images/icon-jobfile.svg'" />
            </span>
              <span>Multi Member Integration</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="clearfix"></div>
  </div>
  <!-- End Sidebar -->
</template>

<style lang="scss">
@import '~metismenujs/scss/metismenujs';
</style>
<style lang="scss">
.bni-hijau {
  background-color: #186984;
  color: white;
}
</style>
