import axios from 'axios'

let getSellingUrl = '/selling';

export const state = {
    listSelling: [],
    listDocCode: [],
    listTaxType: [],
    listFacilityCode: [],
    listDocumentTransaction: [],
    dataSingleSelling: {},
    dataItemSelling: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListSelling: (state, payload) => {
        state.listSelling = payload
    },
    setListDocCode: (state, payload) => {
        state.listDocCode = payload
    },
    setListTaxType: (state, payload) => {
        state.listTaxType = payload
    },
    setListFacilityCode: (state, payload) => {
        state.listFacilityCode = payload
    },
    setListDocumentTransaction: (state, payload) => {
        state.listDocumentTransaction = payload
    },
    setSelling: (state, payload) => {
        state.dataSingleSelling = payload
    },
    setItemSelling: (state, payload) => {
        state.dataItemSelling = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListSelling: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getSellingList?id_job_order=${payload}`)
        context.commit('setListSelling', data)
        return data
    },
    getSelling: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getSelling/${payload}`)
        context.commit('setSelling', data)
        return data
    },
    getItemSelling: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getItemSelling/${payload}`)
        context.commit('setItemSelling', data)
        return data
    },
    getListDocCode: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getDocCode`)
        context.commit('setListDocCode', data)
        return data
    },
    getListDocCodeV2: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getDocCodeV2`)
        context.commit('setListDocCode', data)
        return data
    },
    getListTaxType: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getTaxType?doc_code=${payload}`)
        context.commit('setListTaxType', data)
        return data
    },
    getListTaxTypeV2: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getTaxTypeV2?doc_code=${payload}`)
        context.commit('setListTaxType', data)
        return data
    },
    getListFacilityCode: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getFacilityCode?tax_type=${payload.taxType}&doc_code=${payload.docCode}`)
        context.commit('setListFacilityCode', data)
        return data
    },
    getListDocumentTransaction: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getDocumentTransaction?tax_type=${payload.taxType}&doc_code=${payload.docCode}`)
        context.commit('setListDocumentTransaction', data)
        return data
    },
    savePort: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getSellingUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getPort: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getSellingUrl+'/'+payload}`)
            context.commit('setPort', data)
						console.log('Port', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updatePort: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getSellingUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deletePort: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getSellingUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    saveSelling: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/store`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setSelling', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    setTimeout(() => commit('setSelling', {}), 1000)
                    reject(err)
                })
        })
    },
    updateSelling: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/update`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    reject(err)
                })
        })
    },
    updateSellingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/updateItem`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    reject(err)
                })
        })
    },
    saveNewSellingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/storeNewItem`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteSellingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/destroyItem/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteSelling: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/destroySelling/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    reject(err)
                })
        })
    },
    submitAOL: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/submitAOL/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSelling', {}), 1000)
                    reject(err)
                })
        })
    },
}
